import React, { useState, useTransition } from "react"
import { useTranslation } from "react-i18next"
import './index.scss'
import cx from 'classnames'
import ReloadButton from "@/components/ReloadButton"
const NetworkError = () => {
  const {t} = useTranslation()
 

  return (
    <div className="text-secondary text-lg h-[100vh] w-[100vw] font-bold flex justify-center items-center" style={{
      background: 'var(--bg-linear-gradient-color)'
    }}>
      {t(`Network Error, Refresh the Page and Try Again`)}
      <ReloadButton />

    </div>
  )
}

export default NetworkError
