import { Avatar, Col } from "antd";
import { convertToBigEyeRoad, convertToBigRoad, convertToCockroachRoad, convertToRoadCells, convertToSmallRoad } from "./util";
import './index.scss'



// Base styles for each road type
const baseStyle = {
    width: 15,
    height: 15,
    lineHeight: '15px',
    fontSize: 18,
    border: 'none',
};

const styles = {
    banker: { ...baseStyle, backgroundImage: 'linear-gradient(180deg, #df0900, #a80500)', color: 'white' },
    player: { ...baseStyle, backgroundImage: 'linear-gradient(180deg, #0db6ed, #09428f)', color: 'white' },
    tie: { ...baseStyle, backgroundImage: 'linear-gradient(180deg, #28d309, #1d550a)', color: 'white' },
    empty: { ...baseStyle },
};

const getAvatarStyle = (type) => {
    switch (type) {
        case 0: return styles.banker;
        case 1: return styles.player;
        case 2: return styles.tie;
        default: return styles.empty;
    }
};

const RoadSprite = ({ results }) => {
    const renderRoad = (roads, type) => {
        switch (type) {
            case 'bigRoad':
                return <BigRoad roads={roads} />;
            case 'bigEyeBoy':
                return <BigEyeBoy roads={roads} />;
            case 'smallRoad':
                return <SmallRoad roads={roads} />;
            case 'cockroachRoad':
                return <CockroachRoad roads={roads} />;
            default:
                return null;
        }
    };

    return (
        <div className="roadmap">
            <div className="roadmap-section">
                <div className="roadmap-title">珠盘</div>
                <PearlRoadMap roads={results} />
            </div>
            <div className="roadmap-section">
                <div className="roadmap-title">大路</div>
                <div className="roadmap-content">{renderRoad(results, 'bigRoad')}</div>
            </div>
            <div className="roadmap-section">
                <div className="roadmap-title">大眼仔</div>
                <div className="roadmap-content">{renderRoad(results, 'bigEyeBoy')}</div>
            </div>
            <div className="roadmap-section">
                <div className="roadmap-title">小路</div>
                <div className="roadmap-content">{renderRoad(results, 'smallRoad')}</div>
            </div>
            <div className="roadmap-section">
                <div className="roadmap-title">蟑螂路</div>
                <div className="roadmap-content">{renderRoad(results, 'cockroachRoad')}</div>
            </div>
        </div>
    );
};
const renderResult = (result, index) => {
    const style = getAvatarStyle(result);
    const label = result === 0 ? 'B' : result === 1 ? 'P' : result === 2 ? 'T' : '';
    return (
        <div key={index} className="live-result-item flex align-items-center">
            {label && <Avatar style={style} size="small">
                {label}
            </Avatar>}
        </div>
    );
};
const PearlRoadMap = ({ roads }) => {
    // if result length < 6 * 12, pad -1
    const resultsLength = roads.length;
    const padLength = 6 * 12 - resultsLength;
    const paddedResults = [...roads, ...Array(padLength).fill(-1)];



    return (
       <div className="baccarat-road">
            <Col className="live-result-table">
                {paddedResults.map((result, index) => renderResult(result, index))}
            </Col>
        </div>
    );
};
const renderCell = (cell, index) => {
    const label = cell === 0 ? 'B' : cell === 1 ? 'P' : 'N';
    return (
        <div key={index} className="road-cell flex justify-center items-center">
            <div className={"road-cell-circle-" + label}></div>
        </div>
    );
};
const BigRoad = ({ roads }) => {
    const bigRoad = convertToBigRoad(roads);
    const roadCells = convertToRoadCells(bigRoad)

    return (
        <div className="baccarat-road">
            <Col className="live-result-table bigroad">
                {roadCells.map((row, rowIndex) => renderResult(row, rowIndex))}
            </Col>
            {/* <Col className="live-result-table bigroad">
                {bigRoad.map((row, rowIndex) => (
                    <div key={rowIndex} className="road-line">
                        {row.map((cell, colIndex) => renderCell(cell, colIndex))}
                        {row.length < 6 && Array(6 - row.length).fill(0).map((_, index) => (
                            <div key={index} className="road-cell flex align-items-center">
                            </div>
                        ))}
                    </div>
                ))}
                {bigRoad.length < 16 && Array(16 - bigRoad.length).fill(0).map((_, index) => (
                    <div key={index} className="road-line">
                        {Array(6).fill(0).map((_, index) => (
                            <div key={index} className="road-cell flex align-items-center">
                            </div>
                        ))}
                    </div>
                ))}
            </Col> */}
        </div>
    );
};


const BigEyeBoy = ({ roads }) => {
    const bigRoad = convertToBigRoad(roads);
    const bigEyeBoyRoad = convertToBigEyeRoad(bigRoad);
    const roadCells = convertToRoadCells(bigEyeBoyRoad);

    return (
       <div className="baccarat-road">
            <Col className="live-result-table bigeyeboy">
                {roadCells.map((row, rowIndex) => renderCell(row, rowIndex))}
            </Col>
        </div>
    );
};

const SmallRoad = ({ roads }) => {
    const bigRoad = convertToBigRoad(roads);
    const smallRoad = convertToSmallRoad(bigRoad);
    const roadCells = convertToRoadCells(smallRoad);

    return (
       <div className="baccarat-road">
            <Col className="live-result-table smallroad">
                {roadCells.map((row, rowIndex) => renderCell(row, rowIndex))}
            </Col>
        </div>
    );
};

const CockroachRoad = ({ roads }) => {
    const bigRoad = convertToBigRoad(roads);
    const cockroachRoad = convertToCockroachRoad(bigRoad);
    const roadCells = convertToRoadCells(cockroachRoad);

    return (
       <div className="baccarat-road">
            <Col className="live-result-table cockroachroad">
                {roadCells.map((row, rowIndex) => renderCell(row, rowIndex))}
            </Col>
        </div>
    );
};


export {
    PearlRoadMap,
    BigRoad,
    BigEyeBoy,
    SmallRoad,
    CockroachRoad,
    RoadSprite,
};