import React, { useEffect, useRef, useTransition } from "react"
import aceLogo from '@/assets/ace_logo_new.png';
import { useTranslation } from "react-i18next"
import loginButton from '@/assets/loginbutton.png'
import landingBg from '@/assets/landing_bg.jpeg'
import landingLine from '@/assets/landing_line.png'
import landingLineBtn from '@/assets/landing_line_btn.png'
import landingTelegram from '@/assets/landing_telegram.png'
import landingLogo from '@/assets/ace-landing-logo.png'
import './index.scss'
import MetaPixel from "@/components/pixels/MetaPixel";
import { Image, Typography } from "antd";
import logoAB from '@/assets/logo_AB.png';
import logoAG from '@/assets/logo_AG.png';
import logoDG from '@/assets/logo_DG.png';
import logoAE from '@/assets/logo_AE.png';
import { ReactComponent as MyLandingTg} from '@/assets/myLandingTg.svg';
import { ReactComponent as MyLandingLine} from '@/assets/myLandingLine.svg';
const MyLanding = () => {

  const trackAndGoTo = (location, event = 'Purchase') => {
    fbq('track', event);
    window.open(location);
  }

  return (
    <div>
    <div className="my-landing-container select-none relative h-screen flex flex-col justify-center items-center font-bold text-center">
      <Image className="my-landing-logo max-w-[350px]" src={landingLogo} preview={false} />
       <Typography className="mt-2 text-white text-[25px] font-normal sm:text-[22px]">揭開終極賭博技巧的秘密<br />趕緊加入社區獲取勝利秘</Typography>
       <Typography className="mt-2 font-light text-[14px] px-[15px]">Ace Hackers 是一個包括計算機科學、資訊技術工程、軟件工程、設計和商業管理的多樣化個體組成的團體。我們旨在挑戰賭場系統，並對世界產生重大影響</Typography>
      <button className="my-landing-btn flex justify-center items-center gap-2 blue mt-3" onClick={() => trackAndGoTo('https://t.me/acehackers60', 'Purchase')}>Ace Hackers Telegram <MyLandingTg width={16} fill="#34CDFF"/> </button>
      {/* <button className="my-landing-btn flex justify-center items-center gap-2 green mt-3" onClick={() => trackAndGoTo('https://lin.ee/i7A17F8', 'Purchase')}>Ace Hackerse Line <MyLandingLine width={16} fill="#6AFF34" /> </button> */}
      <div className="mt-4 grid grid-cols-4 w-full my-landing-games place-items-center">
        <img alt="logo" src={logoAG} />
        <img alt="logo" src={logoDG} />
        <img alt="logo" src={logoAB} />
        <img alt="logo" src={logoAE} />
      </div>
    </div>
    <MetaPixel />
  </div>)
}

export default MyLanding
