import React, { useCallback, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import DashboardFooter from './components/DashboardFooter/DashboardFooter';
import DashboardHeader from 'components/DashboardHeader/DashboardHeader';
import getReduxStore from './store';
import { Provider } from 'react-redux';
import InstagramLogin from './pages/OAuth/InstagramLogin';
// import InstagramLogin from "react-instagram-oauth";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"

function App() {

  const responseInstagram = (err, data) => {
    console.log(err, data);
  };

  const handleVerify = (token) => {
    console.log(token);
  }

  const [token, setToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submitting token", token);

    } catch (err) {
      console.log(err);
    }
  };
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(new Date().getTime());

  const onVerify = useCallback((token) => {
    setToken(token);
    // setRefreshReCaptcha(new Date().getTime());
  },[])
  
  return (
    <div className="App">
      <DashboardHeader />

      <div className="App">
      <form onSubmit={handleSubmit}>
        <button type="submit">Submit</button>
        <GoogleReCaptchaProvider 
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      </form>



      <InstagramLogin
          onSuccess={responseInstagram}
          onFailure={responseInstagram}
          clientId={"1154221955814863"}
          redirectUri={"https://localhost:3000/"}
        />
    </div>
    
      <DashboardFooter />
    </div>
  );
}

export default App;
