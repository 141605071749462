import Spinner from "./Spinner";

const PageLoadingSpinner = () => {
    return (
        <div className="w-[100vw] h-[100vh] p-8 flex justify-center" style={{
            backgroundColor: 'var(--bg-linear-gradient-color-darken)', 
        }}>
            <Spinner />
        </div>
    )
}

export default PageLoadingSpinner;