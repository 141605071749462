import { Col, Row } from "antd";
import partner_ae_casino from '@/assets/partner_ae_casino.png';
import partner_allbet from '@/assets/partner_allbet.png';
import partner_asia_gaming from '@/assets/partner_asia_gaming.png';
import partner_big_gaming from '@/assets/partner_big_gaming.png';
import partner_casino from '@/assets/partner_casino.png';
import partner_dream_gaming from '@/assets/partner_dream_gaming.png';
import partner_ebet from '@/assets/partner_ebet.png';
import partner_evolution from '@/assets/partner_evolution.png';
import partner_gameplay from '@/assets/partner_gameplay.png';
import partner_sa_gaming from '@/assets/partner_sa_gaming.png';
import './DashboardFooter.scss';
import { t } from "i18next";

const DashboardFooter = () => {
    return (
        <footer style={{
            backgroundColor: 'var(--bg-linear-gradient-color-darken)', 
        }}>
            <Row className='pt-12 px-3'>

                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_dream_gaming} alt="Partner Dream Gaming" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_allbet} alt="Partner Allbet" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_ae_casino} alt="Partner AE Casino" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_asia_gaming} alt="Partner Asia Gaming" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_big_gaming} alt="Partner Big Gaming" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_casino} alt="Partner Casino" />
                </Col>

                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_ebet} alt="Partner Ebet" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_evolution} alt="Partner Evolution" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_gameplay} alt="Partner Gameplay" />
                </Col>
                <Col span={6} md={3} className="footer-logo self-center">
                    <img src={partner_sa_gaming} alt="Partner SA Gaming" />
                </Col>
            </Row>
            <div className="mt-2 px-3 pb-3" style={{ color: 'rgb(67, 57, 82)' }}>{t('Copyright © 2024 ACEHACKERS. All Rights Reserved.')}</div>
        </footer>
    );
}

export default DashboardFooter;
