import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import Register, { Login } from '@/pages/Onboarding';
import Lobby from '@/pages/Lobby';
import Dashboard from '@/pages/Dashboard';
import Landing from '@/pages/Landing';
import { Provider } from 'react-redux';
import getReduxStore from '@/store';
import ErrorBoundary from '@/components/ErrorBoundary';
import NotFound from '@/pages/NotFound';
import { 
    routePaths
} from './routes'
import PrivateRoutes from './PrivateRoutes';
import NetworkError from '@/pages/NetworkError';
import LineLoginRedirect from '@/pages/OAuth/LineLoginRedirect';
import MyLanding from '@/pages/Landing/MyLanding';

const {

    root,
    register,
    login,
    lobby,
    landing,
    dashboard,
    
} = routePaths


const router = createBrowserRouter([
    {
        path: "/error",
        element: <NetworkError />,   
    },
    {
        path: "/",
        element: <Navigate to={routePaths.lobby} />,
        errorElement: <NetworkError />,
    },
    {
        path: routePaths.root,
        element: <App />,
        errorElement: <NetworkError />

    },
    {
        path: routePaths.landing,
        element: <Landing />,
        errorElement: <NetworkError />

    },
    {
        path: routePaths.myLanding,
        element: <MyLanding />,
        errorElement: <NetworkError />

    },
    {
        path: routePaths.register,
        element: <Register />,
        errorElement: <NetworkError />

    },
    {
        path: routePaths.login,
        element: <Login />,
        errorElement: <NetworkError />

    },
    {
        element: <PrivateRoutes />,
        children: [{
            path: routePaths.lobby,
            element: <Lobby />,
            errorElement: <NetworkError />
    
        },
        {
            path: routePaths.dashboard,
            element: <Dashboard />,
            errorElement: <NetworkError />
            // element: <DashboardSync />
        },]
    },
    
    {
        path: routePaths.lineLogin,
        element: <LineLoginRedirect/>
    },

    {
        path: "*",
        element: <NotFound />
    }
]);


export default router;

// Export navigate function to use outside components
export const navigate = (to, options = {}) => {
    router.navigate(to, options);
};