import { Button, ConfigProvider, Form, Input, Modal, theme } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import './ChangePasswordModal.scss';
import { useMutation } from '@tanstack/react-query';
import { changePassword } from '@/util/fetches';
import NotificationModal from './NotificationModal';
export default NiceModal.create(({ title = "Change Password" }: { title: string }) => {
  // Use a hook to manage the modal state
  //   const modal = useModal();

  const modal = useModal();

  const showModal = () => {
  };
  const handleOk = () => {
  };

  const handleCancel = () => {
  };

  let { t } = useTranslation()

  // states
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');


  const mutation = useMutation({
    mutationFn: changePassword,
    onSuccess(data, variables, context) {
      console.log('change password result', data);
      if (data.data['success']) {
          modal.remove()
          NiceModal.show(NotificationModal, {
            title: t('Password Changed'),
          });
      } else {
          console.log('change password failed', data.data['message']);
          NiceModal.show(NotificationModal, {
              title: 'Change Password Failed',
              body: data.data['message'],
              seconds: 3,
              type: 'error'
          });
      }
  },
  })
  return (
    <ConfigProvider theme={{
      // 1. Use dark algorithm
      algorithm: theme.darkAlgorithm,
      components: {
        Button: {
          /* here is your component tokens */
          defaultHoverColor: 'white',
        },
      },
      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }}>
      <Modal
        title={<h2 className='text-2xl mb-4'>{t(title)}</h2>}
        onOk={() => {
          modal.hide()
        }}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {/* <Button>Custom Button</Button>
            <CancelBtn /><OkBtn /> */}
            <Button 
              onClick={() => {
                mutation.mutate({
                  oldPassword: currentPassword,
                  newPassword: password
                })
              }}
              disabled={currentPassword && password && password2 && password === password2 ? false : true}
              className='ant-btn ant-btn-default flex items-center justify-center flex-nowrap base_button_primary w-100' name='Confirm'>
              {t('CONFIRM')}
            </Button>
          </>
        )}
      >
        <Form>
          <Form.Item
            // label="Username"
            name="current_password"
            rules={[{ required: true, message: 'Please input current password!' }]}
          // hidden
          >
            <Input.Password
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
              placeholder={t('Current Password')} style={{
                backgroundColor: 'var(--bg-linear-gradient-color-darken)',
                border: '2px solid var(--bg-linear-gradient-color)',
                borderRadius: 0,
                color: '#fff',
                height: 56,
              }} />
          </Form.Item>
          <Form.Item
            // label="Username"
            name="password"
            rules={[{ required: true, message: 'Please input new password!' }]}
          // hidden
          >
            <Input.Password
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={t('New Password')} style={{
                backgroundColor: 'var(--bg-linear-gradient-color-darken)',
                border: '2px solid var(--bg-linear-gradient-color)',
                borderRadius: 0,
                color: '#fff',
                height: 56,
              }} />
          </Form.Item>

          <Form.Item
            // label="Password"
            name="confirm_password"
            dependencies={['password']}
            rules={[{ required: true }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),]}
          // hidden
          >
            <Input.Password
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
              placeholder={t('Confirm Password')} style={{
                backgroundColor: 'var(--bg-linear-gradient-color-darken)',
                border: '2px solid var(--bg-linear-gradient-color)',
                borderRadius: 0,
                color: '#fff',
                height: 56,
              }} />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
});