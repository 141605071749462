import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Create the context
const UrlParamsContext = createContext({
    setPlatform: (platformId) => {},
    setHacker: (hackerId) => {},
    setTable: (tableId) => {},
    setActive: (active) => {},
});

// Provider component
export const UrlParamsProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const setUrlParam = useCallback((param, value) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(param, value);
        navigate(`${location.pathname}?${queryParams.toString()}`);
    }, [navigate, location]);

    const value = {
        setPlatform: (platformId) => setUrlParam('platform', platformId),
        setHacker: (hackerId) => setUrlParam('hacker', hackerId),
        setTable: (tableName) => setUrlParam('table', tableName),
        setActive: (active) => setUrlParam('active', active ? 'true' : 'false'),
    };

    // const value = {
    //     setPlatform: (platformId) => {},
    //     setHacker: (hackerId) => {},
    //     setTable: (tableName) => {},
    //     setActive: (active) => {},
    // }; 

    return (
        <UrlParamsContext.Provider value={value}>
            {children}
        </UrlParamsContext.Provider>
    );
};

// Custom hook to use the context
export const useUrlParams = () => {
    const context = useContext(UrlParamsContext);
    if (context === null) {
        throw new Error('useUrlParams must be used within a UrlParamsProvider');
    }
    return context;
};