import { getToken } from '@/util/auth';
import { Navigate, Outlet } from 'react-router-dom';
import { routePaths } from './routes';

const PrivateRoutes = () => {
    // You should replace this with your actual authentication check
    const isAuthenticated = Boolean(getToken());

    return isAuthenticated ? <Outlet /> : <Navigate to={routePaths.login} replace />;
};

export default PrivateRoutes;