import { useEffect, useRef } from "react"

export default function BackgroundVideo({
    src
}) {

    const videoRef = useRef(null);

    // play the video
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);
    return (<video
        ref={videoRef}
        className="fixed top-0 left-0 w-full h-full object-cover z-[-1]"
        style={{
            backgroundColor: 'var(--bg-root)',
        }}
        loop
        playsInline
        autoPlay
        muted
        // controlsList="nofullscreen"
        src={src}
    ></video>)
}