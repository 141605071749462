import NotificationModal from "@/components/Modal/NotificationModal"
import { navigate } from "../router/router"
import { routePaths } from "../router/routes"
import { removeToken, setToken } from "./auth"
import NiceModal from "@ebay/nice-modal-react"

const createAxiosInstance = async () => {
  const axios = await import("axios")
  // [isDEV] in csr development means true
  const baseURL = process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://acehackers.com"

  return axios.default.create({
    baseURL,
  })
}

const fetcher = await createAxiosInstance()

fetcher.interceptors.request.use((config) => {
  // 从localStorage中获取token
  const token = localStorage.getItem('token');
  
  if (!config.headers) {
    config.headers = {};
  }

  // 如果存在token，则将其添加到Authorization头中
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // 对于注册请求，带上对应的 X-ACE-AGENT-CODE=code
  // only for register request
  if (config.url?.startsWith('/api/ace/register')) {
    const agentCode = localStorage.getItem('ace-agent-code');
    if (agentCode) {
      config.headers['X-ACE-AGENT-CODE'] = agentCode
    }
  }

  return config;
});

fetcher.interceptors.response.use((response) => {
  // 从响应头中获取新的token
  // 判断包含 Token Header
  if (Object.keys(response.headers).indexOf('token') !== -1) {
    const newToken = response.headers['token'];

    // 如果存在新的token，则将其保存到localStorage中
    if (newToken) {
      console.log('newToken', newToken)
      setToken(newToken)
    } else {
      console.log('redirect to login', newToken)
      NiceModal.show(NotificationModal);
      removeToken()
      navigate(routePaths.login)
    }
  }
  
  if (response.data && response.data.code === 500 && response.data.message === 'You are banned!') {
    NiceModal.show(NotificationModal, {
      title: 'Request Failed',
      body: response.data.message,
      seconds: 5,
      type: 'error'
    });
    // removeToken()
    navigate(routePaths.login)
  }
  return response;
});

export default fetcher
