export const getToken = () => {
    return localStorage.getItem('token');
}
export const removeToken = () => {
    localStorage.removeItem('token');
}
export const setToken = (val) => {
    if (val.startsWith('Bearer ')) {
        val = val.slice(7);
    }
    localStorage.setItem('token', val);
}