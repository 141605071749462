import { Suspense, lazy } from "react"
import Spinner from "@/components/Spinner"
import DashboardSync from "./Dashboard"
import PageLoadingSpinner from "@/components/PageLoadingSpinner"
const DashboardLazy = lazy(() => import("./Dashboard"))

const Dashboard = (props: any) => {
  return (
    <Suspense fallback={<PageLoadingSpinner />}>
      <DashboardLazy {...props} />
    </Suspense>
  )
}

export default DashboardSync