import fetcher from "@/util/fetcher"
import { setToken } from "./auth"
import { navigate } from "../router/router"
import NiceModal from "@ebay/nice-modal-react"
import NotificationModal from "@/components/Modal/NotificationModal"




export const ping = async () => {
    const resp = await fetcher.post('/api/ace/ping')
    return resp
}


export const login = async ({phone, password, token}) => {
    const resp = await fetcher.post('/api/ace/login?token=' + token, {
        phone,
        password,
    })
    if (resp.data.success) {
        setToken(resp.data.data)
    } else { // show modal
        NiceModal.show(NotificationModal, {
            title: 'Login Failed',
            body: resp.data.message,
            seconds: 3,
            type: 'error'
        });
    }
    return resp
}

export const register = async ({phone, country, currency, otp = '', password, token}) => {
    const config: any = {}

    const resp = await fetcher.post(`/api/ace/register?token=${token}&phone=${phone}&otp=${otp}&country=${encodeURIComponent(country)}`, {
        phone,
        otp,
        country,
        currency,
        password
    }, config)
    if (resp.data.success) {
        setToken(resp.data.data)
    } else { // show modal
        NiceModal.show(NotificationModal, {
            title: 'Register Failed',
            body: resp.data.message,
            seconds: 3,
            type: 'error'
        });
    }
    // console.log("resigster", resp)
    return resp
}
export const sendOtp = async ({phone, country, token}) => {
    const encodedPhone = encodeURIComponent(phone);
    const encodedCountry = encodeURIComponent(country);
    let hasError = false;
    try {
    const resp = await fetcher.post(`/api/ace/sms/send?token=${token}&phone=${encodedPhone}&country=${encodedCountry}`, {});
    if (!resp.data.success) {
        NiceModal.show(NotificationModal, {
            title: 'Send OTP Failed',
            body: resp.data.message,
            seconds: 3,
            type: 'error'
        });
    }
    return resp;
    } catch (e) {
        hasError = true;
        NiceModal.show(NotificationModal, {
            title: 'Send OTP Failed',
            seconds: 3,
            type: 'error'
        });
    }
    return null;
}

export const sendOtpForgotPassword = async ({phone, country, token}) => {
    const encodedPhone = encodeURIComponent(phone);
    const encodedCountry = encodeURIComponent(country);
    let hasError = false;
    try {
    const resp = await fetcher.post(`/api/ace/sms/send/forgot-password?token=${token}&phone=${encodedPhone}&country=${encodedCountry}`, {});
    if (!resp.data.success) {
        NiceModal.show(NotificationModal, {
            title: 'Send OTP Failed',
            body: resp.data.message,
            seconds: 3,
            type: 'error'
        });
    }
    return resp;
    } catch (e) {
        hasError = true;
        NiceModal.show(NotificationModal, {
            title: 'Send OTP Failed',
            seconds: 3,
            type: 'error'
        });
    }
    return null;
}

export const changePassword = async ({oldPassword, newPassword}) => {
    const resp = await fetcher.post('/api/ace/user/change-password', {
        oldPassword,
        newPassword
    })
    return resp
}

export const resetPassword = async ({phone, country, token, code, password}) => {
    const resp = await fetcher.post('/api/ace/user/reset-password?token=' + token, {
        phone,
        country,
        code,
        password
    })
    return resp
}

export const getUserProfile = async () => {
    const resp = await fetcher.post('/api/ace/user')
    return resp.data
}


/*

[
    { name: "EBET", id: "EB" },
    { name: "Big Gaming", id: "BG" },
    { name: "Sexy Baccarat", id: "SB" },
    { name: "Dream Gaming", id: "DG" },
]

*/

export type TAcePlatform = {
    name: string;
    id: string;
};

export const getPlatforms = async () => {
    const resp = await fetcher.post('/api/ace/platforms')
    // console.log('getPlatforms', resp.data)
    return resp.data
}

/*
[
    { id: "#001", name: "Doctor Q", status: "online" },
    { id: "#106", name: "Data Scientist", status: "online" },
    { id: "#787", name: "Cyber Master", status: "online" },
    { id: "#626", name: "Digital Agent", status: "online" },
    { id: "#567", name: "The Unveiler", status: "online" },
]
*/
export const getAceHackers = async () => {
    const resp = await fetcher.post('/api/ace/hackers')
    return resp.data
}


/*
[
    {
      name: "Fantasy Baccarat 2",
      winRate: 94,
  },
    {
      name: "Baccarat 53",
      winRate: 92,
  },
    {
      name: "Baccarat 7",
      winRate: 91,
  },
    {
      name: "VIP",
      winRate: 78,
  },
    {
      name: "Fantasy Baccarat 1",
      winRate: 76,
    }
  ]
*/
export const getTableList = async ({platform, hacker}) => {
    // console.log('getTableList', platform, hacker)
    const resp = await fetcher.post(`/api/ace/table`, {
        platform,
        hacker,
    })
    return resp.data
}

export const getAceToken = async () => {
    const resp = await fetcher.post('/api/ace/user/token')
    return resp.data.data
}
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


export const getTablePredication = async ({platform, hacker, table}) => {
    // await delay(2000)
    const resp = await fetcher.post(`/api/ace/table/predict`, {
        platform,
        hacker,
        table,
    })
    return resp.data
}

export const getUserToken = async () => {
    const resp = await fetcher.post('/api/ace/user/token')
    return resp.data
}

export const getCommunities = async () => {
    const resp = await fetcher.post('/api/ace/public/communities')
    // console.log('getCommunities', resp.data)
    return resp.data
}


export const fetchLineToken = async ({ code, state, country }) => {
    const resp = await fetcher.post(`/api/oauth/line/login?code=${code}&state=${state}&country=${country}`);

    if (resp.data.success) {
        setToken(resp.data.data)
    } else { // show modal
        NiceModal.show(NotificationModal, {
            title: 'Login Failed',
            body: resp.data.message,
            seconds: 3,
            type: 'error'
        });
    }
    return resp.data;
}

// daily sign in and check sign in, both body only post

// /api/ace/user/sign-in
export const dailySignIn = async () => {
    const resp = await fetcher.post('/api/ace/user/sign-in')
    return resp
}


// /api/ace/user/sign-in/check
export const checkSignIn = async () => {
    const resp = await fetcher.post('/api/ace/user/sign-in/check')
    return resp
}