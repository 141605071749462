import { Suspense, lazy } from "react"
import Spinner from "@/components/Spinner"
import PageLoadingSpinner from "@/components/PageLoadingSpinner"

const OnboardingLazy = lazy(() => import("./Lobby"))

const Lobby = (props: any) => {
  return (
    <Suspense fallback={<PageLoadingSpinner />}>
      <OnboardingLazy {...props} />
    </Suspense>
  )
}

export default Lobby