import React, { useEffect } from 'react';
import { ConfigProvider, Modal } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { t } from 'i18next';

// This function creates a countdown modal component using NiceModal
export default NiceModal.create(({
  seconds = 3,
  title = "You are logged out!",
  body = "",
  type = "ok",
}: { seconds: number, title: string, body: string, type: "ok" | "error" }) => {
  const modal = useModal();

  const [sec, setSecs] = React.useState(seconds);

  useEffect(() => {

    const timer = setInterval(() => {
      setSecs((prev) => prev - 1);
      if (sec <= 0) {
        clearInterval(timer);
        modal.remove();
      }
    }, 1_000);

    return () => {
      clearInterval(timer); // Clean up the interval when the modal is closed or component is unmounted
    };
  }, [modal, sec, seconds]);

  // Determine the type of modal to display based on the passed 'type'
  const modalType = type === "ok" ? "Success" : "Error";

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'rgb(42, 59, 52)',
          borderRadius: 2,

          // Alias Token
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Modal
        title={`${t(modalType)} - ${t(title)}`}
        onOk={() => modal.hide()}
        visible={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
        cancelButtonProps={{ style: { display: 'none' }}}
      >
        {body && <p>{t(body)}</p>}
        <p>{t(`modal_countdown`, {
          count: sec
        })}</p>
      </Modal>
    </ConfigProvider>
  );
});
