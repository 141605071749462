import React, { Children, useEffect, useMemo, useState } from 'react';
import './DashboardHeader.scss';
import aceLogo from '@/assets/ace_logo_new.png';
import me from '@/assets/me.png'
import logout from '@/assets/logout.png'
import refresh from '@/assets/refresh.svg'
import flag_zh from '@/assets/lang_cn.png'
import flag_zh_tw from '@/assets/china_tw_flag.svg'
import flag_my from '@/assets/malaysia_flag.svg'
import flag_en from '@/assets/lang_en.png'
import flag_th from '@/assets/lang_thai.png'
import flag_id from '@/assets/lang_indo.png'
import flag_vi from '@/assets/vietnam_flag.svg'
import down from '@/assets/down.png'
import { RootState, userSlice } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined, SyncOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Space, Divider, Button, theme, Collapse, Select, Row } from 'antd';
import type { CollapseProps, DropdownProps, MenuProps } from 'antd';
import i18n, { languages } from '@/i18n/config/i18n';
import { useTranslation } from 'react-i18next';
import { render } from 'react-dom';
import AceToken from '../AceToken';
import { routePaths } from '@/router/routes';
import { navigate } from '@/router/router';
import NiceModal from '@ebay/nice-modal-react';
import ChangePasswordModal from '../Modal/ChangePasswordModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserProfile } from '@/util/fetches';
import NotificationModal from '../Modal/NotificationModal';


const { useToken } = theme;


const getLanguageFromId = (id: string) => {
    const lang = languages.find((lang) => lang.key === id);
    return lang ? lang.label : 'English';
}

const DashboardHeader = () => {
    const dispatch = useDispatch()
    const { token } = useToken();

    // 加载组件，用于翻译，但不限于放在哪一个组件。
    let { t } = useTranslation()
    const username = useSelector<RootState>(
        (state) => state.user.username
    );


    const language = useSelector<RootState>(
        (state) => state.user.language
    );


    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const menuStyle: React.CSSProperties = {
        boxShadow: 'none',
    };

    const items: MenuProps['items'] = useMemo(() => [
        // {
        //     key: '1',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer" href="/ego/userinfo">
        //             {t('Edit User Info')}
        //         </a>
        //     ),
        // },
    ], []);

    const languageMenuItems: MenuProps['items'] = useMemo(() => {


        return languages.map((language) => ({
            key: language.key,
            label: (
                <Space className='flex justify-between items-center'>
                    {renderFlag(language.key)}
                    <span className='text-sm'>{language.label}</span>
                </Space>
            ),
            onClick: () => {
                dispatch(userSlice.actions.setUserLanguage(language.key));
                i18n.changeLanguage(language.key);
            },
        }));
    }, [language]);

    const languageCollapseItems: CollapseProps['items'] = useMemo(() => {


        const lang: any = languages.find((lang) => lang.key === language);

        return ([{
            key: 'language',
            label: <Space className='flex justify-between items-center'>
                {renderFlag(lang.key)}
                <span>{lang.label}</span>
            </Space>,
            children: <div>
                {languages.filter((lang) => lang !== language).map(({ key, label }) => (
                    <Space
                        key={key}
                        onClick={() => {
                            dispatch(userSlice.actions.setUserLanguage(key));
                            i18n.changeLanguage(key);
                        }}
                        className='p-4 cursor-pointer flex justify-between items-center'
                    >
                        {renderFlag(key)}
                        <span>{label}</span>
                    </Space>
                ))}</div>
        }]);
    }, [dispatch, language]);

    const onLogoutClick = () => {
        dispatch(userSlice.actions.logout());
        navigate(routePaths.login);
        NiceModal.show(NotificationModal, {
            title: t('You are logged out!'),
        });
    }

    const onChangePasswordClick = () => {
        NiceModal.show(ChangePasswordModal);
    }


    const [open, setOpen] = useState(false);
    const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleMobileOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setMobileOpen(nextOpen);
        }
    };

    const queryClient = useQueryClient();

    const { data: userProfile, isFetching, isLoading } = useQuery({
        queryFn: getUserProfile,
        queryKey: ['api/ace/user'],
    })

    useEffect(() => {
        if (userProfile) {
            console.log(userProfile)
            let username = userProfile.data?.phone;
            if (username?.startsWith('line:')) {
                username = userProfile.data?.username;
            }
            if (username) {
                dispatch(userSlice.actions.setUser(username))
            }
        }
    }, [dispatch, userProfile])


    return (
        <div id="dashboard-header" className="py-2 px-3 d-flex alignt-items-center">
            <div className="row dashboard-center-width m-auto gx-0 flex-wrap h-100 w-100">
                <div className="col flex justify-start items-center">
                    <div className='flex-1'>
                        <a href={routePaths.lobby}>
                            {/* <img className="img-fluid pt-2 pb-2.5 max-h-16 min-h-10" */}
                            <img className="max-h-14 p-2 pb-1"
                                src={aceLogo}
                                alt="CasinoHack.Club" />
                        </a>
                    </div>

                    <Dropdown
                        className='justify-end items-center flex md:hidden select-none'
                        menu={{ items }}
                        open={open}
                        onOpenChange={handleOpenChange}
                        dropdownRender={(menu) => (
                            <div style={contentStyle}>
                                {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                                <Divider style={{ margin: 0 }} />
                                <Button
                                    className='w-full btn-change-pwd'
                                    onClick={() => {
                                        setOpen(false)
                                        onChangePasswordClick()
                                    }} type="text">{t('Change Password')}</Button>
                            </div>
                        )}
                        // anchor to the right
                        placement='bottomRight'
                    >

                        <Space onClick={(e) => e.preventDefault()}>
                            <img className=''
                                src={me}
                                width={20}
                                height={20}
                                alt="me" />
                            <span>{t("username_prefix")} - {username as string}</span>
                            <DownOutlined />
                        </Space>
                    </Dropdown>

                    <AceToken />
                    {/* drop down to select language */}
                    <Dropdown
                        className='md:hidden flex justify-end items-center select-none'
                        menu={{ items: languageMenuItems }}
                        dropdownRender={
                            (menu) => (

                                <div style={contentStyle}>
                                    {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                                </div>
                            )
                        }

                    >
                        <div className="dropdown-toggle mx-3 p-2 text-base rounded" role="button" style={{ backgroundColor: 'var(--bg-button-dashboard)' }}>
                            {renderFlag(language as string)}
                            <span className='mx-1 text-sm'>{getLanguageFromId(language)}</span>
                            {/* <DownOutlined /> */}
                        </div>
                    </Dropdown>
                    <button onClick={onLogoutClick} className='md:hidden rounded-lg flex justify-around items-center text-white' style={{ backgroundColor: 'var(--bg-linear-gradient-color)', height: 35, borderRadius: 20, padding: '1rem' }}>
                        <img className='mr-1' width={20} height={20} src={logout} alt="" />
                        <span>{t('logout')}</span>
                    </button>
                    <Dropdown
                        trigger={['click']}
                        className='hidden md:flex ml-3 justify-end items-center select-none'
                        menu={{}}
                        onOpenChange={handleMobileOpenChange}
                        open={mobileOpen}
                        dropdownRender={(menu) => (
                            <div className='dropdow_menu_header' style={contentStyle}>
                                <Row className='w-full p-4 cursor-pointer hover:bg-white/20'>
                                    <span>{t("username_prefix")} - {username as string}</span>
                                </Row>
                                <Row onClick={() => {
                                    onChangePasswordClick()
                                    setMobileOpen(false)
                                }} className='w-full p-4 cursor-pointer hover:bg-white/20'>
                                    {t('Change Password')}
                                </Row>
                                <Collapse
                                    defaultActiveKey={['1']}
                                    expandIconPosition='end'
                                    items={languageCollapseItems}
                                />
                                <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                                <button onClick={onLogoutClick} className='w-full rounded-lg p-4 flex justify-around items-center text-white' style={{ minHeight: 42, background: 'var(--bg-button-dashboard)', height: 35, borderRadius: 20 }}>
                                    <div className='flex justify-center items-center'>
                                        <img className='mr-1' width={20} height={20} src={logout} alt="" />
                                        <span>{t('logout')}</span>
                                    </div>
                                </button>
                            </div>
                        )}
                        // anchor to the right
                        placement='bottomRight'
                    >
                        <MenuOutlined className='py-1 px-6 rounded-md' width={20} height={20} style={{ background: 'var(--bg-button-dashboard)', height: 35, borderRadius: 20 }} />
                    </Dropdown>
                </div>
            </div>
        </div>

    );
}


const renderFlag = (language: string) => {
    switch (language) {
        case 'zh':
            // return <img className="locale-flag" width={20} height={20} src={flag_zh} alt="" />;
        case 'zh-TW':
            return <img className="locale-flag" width={20} height={20} src={flag_zh_tw} alt="" />;
        case 'my':
            return <img className="locale-flag" width={20} height={20} src={flag_my} alt="" />;
        case 'en':
            return <img className="locale-flag" width={20} height={20} src={flag_en} alt="" />;
        case 'th':
            return <img className="locale-flag" width={20} height={20} src={flag_th} alt="" />;
        case 'vi':
            return <img className="locale-flag" width={20} height={20} src={flag_vi} alt="" />;
        case 'id':
            return <img className="locale-flag" width={20} height={20} src={flag_id} alt="" />;
        default:
            return <img className="locale-flag" width={20} height={20} src={flag_en} alt="" />;
    }
}


export default DashboardHeader;