export const routePaths = {
    root: "/test",
    register: "/register",
    landing: "/landing",
    myLanding: "/my/landing",
    login: "/login",
    lobby: "/lobby",
    dashboard: "/dashboard",
    lineLogin: "/line-login",
    error: "/error",
};
