import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import getReduxStore from './store';
import ErrorBoundary from './components/ErrorBoundary';
import router from './router/router';
import RoutingComponent from './router/RoutingComponent';
import NiceModal from '@ebay/nice-modal-react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ConfigProvider, theme } from 'antd';
import { useEffect } from 'react';


const {
  QueryClient,
  useQueryErrorResetBoundary,
  QueryClientProvider,
} = await import("@tanstack/react-query")

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      throwOnError: true,
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider theme={{
    // 1. Use dark algorithm
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: '#2a3b34'
    }

    // 2. Combine dark algorithm and compact algorithm
    // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
  }}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}>
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <Provider store={getReduxStore({})} serverState={{}}>
          <QueryClientProvider client={queryClient}>
            <NiceModal.Provider>


              {/* <React.StrictMode> */}
              <RouterProvider router={router} />
              {/* <RoutingComponent /> */}
              {/* </React.StrictMode> */}


            </NiceModal.Provider>
          </QueryClientProvider>
        </Provider>
      </ErrorBoundary>
    </GoogleReCaptchaProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
