import React from "react"

const NotFound = () => {
  return (
    <div 
      style={{
        backgroundColor: 'var(--bg-root)'
      }}
      className="fixed top-0 left-0 w-full h-full text-secondary text-lg font-bold flex justify-center items-center">
      {`You Are Lost  :)`}
    </div>
  )
}

export default NotFound
