/**
 * Convert roads to Big Road format
 * @param {number[]} roads - Array of roads results (0=Banker, 1=Player, 2=Tie)
 * @returns {number[][]} - Two-dimensional array representing the Big Road
 */
const convertToBigRoad = (roads) => {
    const bigRoad = [];
    let currentCol = -1;
    let currentRow = 0;
    let previousResult = null;

    for (let i = 0; i < roads.length; i++) {
        const result = roads[i];
        
        // Ignore Tie results in Big Road calculation
        if (result === 2) {
            continue;
        }

        if (previousResult === null || result !== previousResult) {
            // Start a new column
            currentCol++;
            currentRow = 0;
        } else {
            // Continue in the same column
            currentRow++;
        }

        if (!bigRoad[currentCol]) {
            bigRoad[currentCol] = [];
        }

        bigRoad[currentCol][currentRow] = result;
        previousResult = result;
    }

    return bigRoad;
};

// 适配长龙拐弯
const convertToRoadCells = (roadArr, suggestedNumCols = 16, minNumCols = 10) => {
/*
一个显而易见的问题是，如果连续出现六次以上庄赢或闲赢，显示屏上的行数岂不是不够？这是百家乐玩家求之不得的情形！当标记抵达大路的底部（最后一行）时，只是简单地向右转，在最后一行向右延伸。一旦向右转后，就形成了一个叫”长龙”的局面。大多数百家乐玩家认为，一旦长龙出现，你必须跟随，于是出现了这样一个口号”跟随长龙”。当然，长龙越长越好。


图解三：第4至8列的双跳、第9至17列的单跳以及第17列的十次闲家连胜的长龙

双龙
如果在大路上竖直向下走时遇上长龙，就马上右转（无论你在哪一行）来制造一条新的长龙。有些玩家称之为”双龙”。

在极其罕见（让玩客欣喜若狂）的情况下，在大路上出现很多条龙，让人眼花缭乱。在图解四中你可能注意到了，这些圈之间有很细微的几乎察觉不出的连接线，这样你可以判断出哪个圈和哪个圈相连。
*/
    // 每列的最大高度（行数）=6
    const colMaxLength = {}; // 每当有长龙出现时，在右边一列的最大高度填充，并更新对应列的最大长度 -= 1
    // big road = [[1,1], [0], [1,1,1]]
    // 0. 准备一个一位数组，长度为 100 * 6
    const bigRoadCells = new Array(100 * 6).fill(-1);

    let maxColNumber = 0;

    // 1. 遍历每一列
    for (let i = 0; i < roadArr.length; i++) {
        // 2. 遍历每一行
        let currCol = i;

        if (colMaxLength[i] === undefined) {
            colMaxLength[i] = 6;
        }
        for (let j = 0; j < roadArr[i].length; j++) {

            if (j < colMaxLength[i]) {
                // 3. 计算当前单元格的索引
                const index = i * 6 + j;

                // 4. 将 big road 的值存入 big road cells
                bigRoadCells[index] = roadArr[i][j];
                maxColNumber = Math.max(maxColNumber, i);
            } else {
                // 5. 如果当前行数大于等于最大高度，向右转
                currCol++;
                colMaxLength[currCol] = colMaxLength[i] - 1;
                const index = (currCol) * 6 + (colMaxLength[currCol]);
                // console.log('exceed max height', i, j, index, colMaxLength[i + 1])

                bigRoadCells[index] = roadArr[i][j];
                maxColNumber = Math.max(maxColNumber, currCol);
            }
        }
    }

    // only returns up to maxColNumber
    let finalColNumber = Math.min(Math.max(suggestedNumCols, maxColNumber), maxColNumber + 2);
    finalColNumber = Math.max(minNumCols, maxColNumber);
    
    return bigRoadCells.slice(0, (finalColNumber + 1) * 6);
}


/**
 * Convert Big Road to Big Eye Road format
 * @param {number[][]} bigRoad - Two-dimensional array representing the Big Road
 * @returns {number[][]} - Two-dimensional array representing the Big Eye Road
 */
const convertToBigEyeRoad = (bigRoad) => {
   return convertToRoadCellsWithLookAhead(bigRoad, 1); 
};

const convertToSmallRoad = (bigRoad) => {
    return convertToRoadCellsWithLookAhead(bigRoad, 2);
}

const convertToCockroachRoad = (bigRoad) => {
    return convertToRoadCellsWithLookAhead(bigRoad, 3);
}


const getColorWithLookAhead = (bigRoad, col, row, lookAhead) => {

    if (row === 0) {
        const left = col - 1 - lookAhead;
        const right = col - 1;
        if (bigRoad[left].length === bigRoad[right].length) {
            return 0; // Red
        }
        return 1; // Blue
    }

    const previousCell = bigRoad[col - lookAhead][row] !== undefined;
    const abovePreviousCell = bigRoad[col - lookAhead][row - 1] !== undefined;



    if (previousCell) {
        return 0; // Red
    } else if (!abovePreviousCell) {
        return 0; // Red
    } else {
        return 1; // Blue
    }
}
const convertToRoadCellsWithLookAhead = (bigRoad, lookAhead) => {
    const result = [];
    if (bigRoad.length <= lookAhead) {
        return result;
    }

    let startCol = lookAhead;
    let startRow = 1;
    if (bigRoad[startCol].length <= startRow) {
        startRow = 0;
        startCol += 1;
    }

    let previousResult = null;
    let currentResult = [];
    for (let col = startCol; col < bigRoad.length; col++) {
        for (let row = col === startCol ? startRow : 0; row < bigRoad[col].length; row++) {
            if (bigRoad[col][row] === undefined) continue;

            const color = getColorWithLookAhead(bigRoad, col, row, lookAhead);
            // console.log(`[${col}, ${row}]=${color}`)
        
            if (previousResult === null) {
                currentResult.push(color);
            } else {
                if (color === previousResult) {
                    currentResult.push(color);
                } else {
                    result.push([...currentResult]);
                    currentResult = [color];
                }
            }

            previousResult = color;


        }
    }
    result.push(currentResult);

    return result;

}
// 示例数据
// const roads = [0, 0, 1, 1, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 2, 0, 0];
const roads = [0, 1, 1, 0, 0, 0, 1, 1, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 2, 1, 1, 0, 1,1, 1, 1, 0, 1, 0, 0, 1]
const bigRoad = convertToBigRoad(roads);
// console.log('bigRoad', bigRoad);
// const bigRoadCells = convertToBigRoadCells(bigRoad);
// console.log('bigRoadCells', bigRoadCells);
const bigEyeRoad = convertToBigEyeRoad(bigRoad);
// console.log('bigEyeRoad', bigEyeRoad);


export {
    convertToBigRoad,
    convertToRoadCells,
    convertToBigEyeRoad,
    convertToSmallRoad,
    convertToCockroachRoad,
    roads as testRoads,
}