import React, { useState, useTransition } from "react"
import { useTranslation } from "react-i18next"
import './index.scss'
import cx from 'classnames'
const ReloadButton = () => {
  const [active, setActive] = useState(false)
  const refreshPage = () => {
    setActive(true)
    window.location.reload();
  };

  return (
    <div
      onClick={refreshPage}
      className={cx("mx-1 reloadSingle", active && "loading")}></div>
  )
}

export default ReloadButton
