import { configureStore, createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import { use } from "i18next"
import i18n, { DEFAULT_LANG } from './i18n/config/i18n'
import Cookies from 'js-cookie';
import { removeToken } from "./util/auth";



function deepMerge(target: any, source: any) {

   // Ensure source is an object; if not, simply return without doing anything
   if (!source || typeof source !== 'object' || Array.isArray(source)) {
    return;
  }

  // Iterate over all properties in the source object
  Object.keys(source).forEach(key => {
    if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
      // If the value is an object (excluding arrays), recursively merge
      if (!target[key]) target[key] = {};  // If the target doesn't have the key, create an empty object
      deepMerge(target[key], source[key]);
    } else {
      // Otherwise, directly set the value
      target[key] = source[key];
    }
  });
}


export const defaultStates = {
  "root": {},
  "user": {
    username: 'Hacker',
    language: DEFAULT_LANG,
    aceToken: 0,
    signInStatus: -1,
  },
};

export const rootSlice = createSlice({
  name: "root",
  initialState: { ...defaultStates.root },
  reducers: {
  },
})

// user slice that holds user information
export const userSlice = createSlice({
  name: "user",
  initialState: { ...defaultStates.user, language: Cookies.get('lang') },
  reducers: {
    logout: (state) => {
      state.username = 'Hacker';
      state.aceToken = 0; 
      removeToken();
    },
    setUser: (state, action: PayloadAction<any>) => {
      console.log("set user", action.payload);
      state.username = action.payload
    },
    setToken: (state, action: PayloadAction<any>) => {
      // console.log("set token", action.payload);
      state.aceToken = action.payload
    },
    refreshAceToken: (state) => {
      console.log("refresh ace token");
      state.aceToken += 1;
    },
    setUserLanguage: (state, action: PayloadAction<string>) => {
      const lang = action.payload;
      console.log("set user language", lang);

      state.language = lang;
      Cookies.set('lang', lang);
    },
    updateSignInStatus: (state) => {
      console.log("update sign in status");
      state.signInStatus = Date.now();
    }
  },
})


const rootReducer = {
  root: rootSlice.reducer,
  user: userSlice.reducer,
}

const getReduxStore = (defaultState: { [x: string]: any }, override: { [x: string]: any } = {}) => {
  // 设置用户初始语言
  // console.log("initialLanguage", defaultState, override);
  const initialState = {
    ...defaultState,
  };

  deepMerge(initialState, override);

  return configureStore({
    reducer: rootReducer as any,
    // middleware: [thunk] as any,
    devTools: false,
    preloadedState: initialState,
  })
}

const initialState: any = getReduxStore({}).getState

export type RootState = ReturnType<typeof initialState>
export default getReduxStore
