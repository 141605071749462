import NotificationModal from '@/components/Modal/NotificationModal';
import { navigate } from '@/router/router';
import { RootState } from '@/store';
import { fetchLineToken } from '@/util/fetches';
import NiceModal from '@ebay/nice-modal-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


/*
country:
case "CN" -> "+86";
case "US" -> "+1";
case "TW" -> "+886";
case "MY" -> "+60";
case "SG" -> "+65";
case "TH" -> "+66";
case "ID" -> "+62";


locale:
{ key: 'en', label: 'English' },
{ key: 'zh', label: '中文' },
{ key: 'zh-TW', label: '中国語' },
{ key: 'my', label: 'Melayu' },
{ key: 'th', label: 'ไทย' },
// 越南
{ key: 'vi', label: 'Tiếng Việt' },
{ key: 'id', label: 'Indonesia' },
*/
const getCountryFromLocale = (locale: string) => {
    switch (locale) {
        case 'zh':
            return 'CN';
        case 'zh-TW':
            return 'TW';
        case 'my':
            return 'MY';
        case 'en':
            return 'US';
        case 'th':
            return 'TH';
        case 'vi':
            return 'VN';
        case 'id':
            return 'ID';
        default:
            return 'TW';
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LineLoginRedirect() {
    const query = useQuery();


    const mutation = useMutation({
        mutationFn: fetchLineToken,
        onSuccess(data) {
            // console.log('Login result', data);
            if (data.success) {
                navigate('/dashboard'); // Assuming you have a route set up for after login
            } else {
                // console.log('Login failed:', data.message);
                NiceModal.show(NotificationModal, { title: 'Login Failed', body: data.message, type: 'error' });
            }
        },
        onError(error) {
            console.error('Login error:', error);
            NiceModal.show(NotificationModal, { title: 'Login Error', type: 'error' });
        }
    });


    const [code, setCode] = useState('');
    const [state, setState] = useState('');

    const isMutationTriggered = useRef(false); // 使用 ref 来跨渲染周期保持状态


    // get locale from user reducer
    const locale = useSelector<RootState>(
        (state) => state.user.language
    ) as string;

    useEffect(() => {
        const _code = query.get('code');
        const _state = query.get('state');

        // console.log('Authorization code:', _code);
        // console.log('State value:', _state);

        // Initialize state only if it's not already set
        if (_code && !code) {
            setCode(_code);
        }
        if (_state && !state) {
            setState(_state);
        }
    }, [code, state, query, mutation]); 


    useEffect(() => {
        if (code && state && !isMutationTriggered.current) { // 检查是否是 mutation 触发的更新
            // 这里调用 mutation.mutate，并且防止它触发无限循环
            isMutationTriggered.current = true; // 设置标志位
            mutation.mutate({ code, state, country: getCountryFromLocale(locale)});
        }
    }, [code, state]); // 依赖项是 code 和 state，只在它们变化时运行



    return (
        <div className='text-white w-full h-[100vh] flex flex-column items-center justify-center'>
            <h1 className='p-4 animate-bounce'>Logging in</h1>
            <p>You have successfully been authenticated by LINE. Please wait...</p>
        </div>
    );
}

export default LineLoginRedirect;
