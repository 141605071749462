import i18n, { languages } from "@/i18n/config/i18n";
import { ConfigProvider, Dropdown, MenuProps, Space, theme } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState, userSlice } from '@/store';
import flag_zh from '@/assets/lang_cn.png'
import flag_zh_tw from '@/assets/china_tw_flag.svg'
import flag_my from '@/assets/malaysia_flag.svg'
import flag_en from '@/assets/lang_en.png'
import flag_th from '@/assets/lang_thai.png'
import flag_id from '@/assets/lang_indo.png'
import flag_vi from '@/assets/vietnam_flag.svg'
import flag_sg from '@/assets/singapore_flag.svg';// 根据当前 i18n locale 决定默认的区号
import './index.scss'
const { useToken } = theme;

/*
add the following style programmatically

    .language-selector {
        position: fixed;
        top: 10px;
        right: 10px;
    }
*/


const LanguageSelector = () => {
    const dispatch = useDispatch()
    const { token } = useToken();

    // 加载组件，用于翻译，但不限于放在哪一个组件。
    let { t } = useTranslation()
    const language: string = useSelector<RootState>(
        (state) => state.user.language
    ) as string;

    const languageMenuItems: MenuProps['items'] = useMemo(() => {


        return languages.map((language) => ({
            key: language.key,
            label: (
                <Space className='flex justify-between items-center'>
                    {renderFlag(language.key)}
                    <span className='text-sm'>{language.label}</span>
                </Space>
            ),
            onClick: () => {
                dispatch(userSlice.actions.setUserLanguage(language.key));
                console.log("changing language", language.key);
                i18n.changeLanguage(language.key);
            },
        }));
    }, [language]);

    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const menuStyle: React.CSSProperties = {
        boxShadow: 'none',
    };

    return (<>
        <ConfigProvider
            theme={{
                // 1. Use dark algorithm
                algorithm: theme.darkAlgorithm,

                // 2. Combine dark algorithm and compact algorithm
                // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
            }}
        >
            <Dropdown
                className='text-white font-bold language-selector flex justify-end items-center select-none'
                menu={{ items: languageMenuItems }}
                dropdownRender={
                    (menu) => (

                        <div style={contentStyle}>
                            {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                        </div>
                    )
                }

            >
                <div className="dropdown-toggle gap-4 p-2 text-base rounded" role="button" style={{ backgroundColor: 'rgba(38, 33, 47, 0.8)' }}>
                    {renderFlag(language as string)}
                    <span className='mx-1 text-sm'>{getLanguageFromId(language)}</span>
                    {/* <DownOutlined /> */}
                </div>
            </Dropdown>
        </ConfigProvider></>)
}
const getLanguageFromId = (id: string) => {
    const lang = languages.find((lang) => lang.key === id);
    return lang ? lang.label : 'English';
}


const renderFlag = (language: string) => {
    switch (language) {
        case 'zh':
            return <img className="locale-flag" width={20} height={20} src={flag_sg} alt="" />;
        case 'zh-TW':
            return <img className="locale-flag" width={20} height={20} src={flag_zh_tw} alt="" />;
        case 'my':
            return <img className="locale-flag" width={20} height={20} src={flag_my} alt="" />;
        case 'en':
            return <img className="locale-flag" width={20} height={20} src={flag_en} alt="" />;
        case 'th':
            return <img className="locale-flag" width={20} height={20} src={flag_th} alt="" />;
        case 'vi':
            return <img className="locale-flag" width={20} height={20} src={flag_vi} alt="" />;
        case 'id':
            return <img className="locale-flag" width={20} height={20} src={flag_id} alt="" />;
        default:
            return <img className="locale-flag" width={20} height={20} src={flag_en} alt="" />;
    }
}
export default LanguageSelector;