import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import HttpMiddleware from 'i18next-http-middleware';

import i18n, { LanguageDetectorModule, t } from "i18next";                     // i18n 的主要模块
import enUsTrans from "../locales/en-us.json";
import zhCnTrans from "../locales/zh-cn.json";
import zhTWTrans from "../locales/zh-tw.json";
import myTrans from "../locales/my.json";
import idTrans from "../locales/id.json";
import thTrans from "../locales/th.json";
import viTrans from "../locales/vi.json";
import { Translation, initReactI18next } from 'react-i18next'; 

export const languages = [
  { key: 'en', label: 'English' },
  { key: 'zh', label: '简体中文' },
  { key: 'zh-TW', label: '繁体中文' },
  { key: 'my', label: 'Melayu' },
  { key: 'th', label: 'ไทย' },
  // 越南
  { key: 'vi', label: 'Tiếng Việt' },
  { key: 'id', label: 'Indonesia' },
  // { key: 'id', label: 'Indonesia' },
];
// const languageDetector = {
//   type: 'languageDetector',
//   detect: () => {
//     let found;
//     // For SSR, read from cookies on the request object
//     // if (req && req.cookies) {
//     //   found = req.cookies[this.options.lookupCookie];
//     // }

//     // For client-side, read from document.cookie
//     if (typeof document !== 'undefined') {
//       const cookie = document.cookie.match('(^|;)\\s*' + 'lang' + '\\s*=\\s*([^;]+)');
//       found = cookie ? decodeURIComponent(cookie.pop()) : null;
//     }
//     console.log("detect working", found);
//     return found;
//   },
//   init: function(services, detectorOptions, i18nextOptions) { // optional since v22.3.0
//     // console.log("init working", services, detectorOptions, i18nextOptions)
//     /* use services and options */
//   },
//   cacheUserLanguage: () => {},
// };
export const DEFAULT_LANG = 'en';


i18n   
.use(Backend)         // 检测当前浏览器的语言或者从服务器获取配置资源,不过也没有什么用处
// .use(languageDetector)
.use(LanguageDetector) //嗅探当前浏览器语言
// .use(HttpMiddleware.LanguageDetector)
// .use(lngDetector)
.use(initReactI18next) //init i18next
.init({
  //引入资源文件
  resources: {
    en: {
      translation: enUsTrans,   // 引入json文件
      // translation: {
      //   "home":'aaa',       //   单独的json语句 
      //    "你好":"hello"    
      // },
    },
    zh: {
      translation: zhCnTrans,
    },
    'zh-TW': {
      translation: zhTWTrans,
    },
    my: {
      translation: myTrans,
    },
    id: {
      translation: idTrans,
    },
    th: {
      translation: thTrans,
    },
    vi: {
      translation: viTrans,
    },
  },
  detection: {
    order: [
      // 'querystring',
      // 'path',
      'cookie', 'header', 'session',
      // 'customDetector'
    ],
    caches: ['cookie'],  // caching options
    lookupCookie: 'lang', // the cookie name where language settings are stored
  },
  //选择默认语言，选择内容为上述配置中的key，即en/zh
  fallbackLng: DEFAULT_LANG, 
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n;
