import React, { useEffect, useRef, useTransition } from "react"
import aceLogo from '@/assets/ace_logo_new.png';
import { useTranslation } from "react-i18next"
import bgVideo1 from '@/assets/login_bg_video1.mp4'
import loginButton from '@/assets/loginbutton.png'
import landingBg from '@/assets/landing_bg.jpeg'
import landingLine from '@/assets/landing_line.png'
import landingLineBtn from '@/assets/landing_line_btn.png'
import landingTelegram from '@/assets/landing_telegram.png'
import landingTelegramBtn from '@/assets/landing_telegram_btn.png'
import './index.scss'
import LanguageSelector from "@/components/LanguageSelector"
import router from "@/router/router"
import { routePaths } from "@/router/routes"
import LottieAnimation from "@/components/LottieAnimation";
import BackgroundVideo from "@/components/BackgroundVideo";
import MetaPixel from "@/components/pixels/MetaPixel";

const Landing2 = () => {
  const { t } = useTranslation()
  const onLoginButtonClick = () => {
    router.navigate(routePaths.login)
  }
  return (
    <div className="landing-container h-screen flex justify-center items-center font-bold text-center">
      <img className='h-14 ml-[-8px] landing-logo' src={aceLogo} alt="Ace Logo" />
      <LanguageSelector />
      <div className="mt-48 flex flex-column justify-center items-center ">
        <p className="lg:max-w-full max-w-[80%] sm:text-4l lg:text-5xl md:text-5xl text-6xl text-white landing-title">{t('AI-POWERED BACCARAT OUTCOME PREDICTION')}</p>
        <p className="text-xl text-white landing-subtitle">{t('A powerful machine-learning model trained on ten-thousands of real Baccarat result, ran through millions of simulations, proven to give safe and confident outcome predictions for games of Baccarat & Roulette.')}</p>
        <div onClick={onLoginButtonClick} className="landing-login-button relative flex justify-center items-center">
          <p className="landing-login-button-text h-24 w-24 flex items-center justify-center font-bold text-2xl absolute text-white rounded-full">{t('LOGIN')}</p>
          <img className="w-48" src={loginButton} alt="login" />
        </div>
      </div>
      {/* <LottieAnimation animationData={require('@/assets/lottie/login_bg_video1.mp4.lottie.json')} /> */}

      <BackgroundVideo src={bgVideo1} />
    </div>
  )
}

const Landing = () => {

  const trackAndGoTo = (location, event = 'Purchase') => {
    fbq('track', event);
    window.open(location);
  }

  return (<div className="select-none relative landing-container h-screen flex justify-center items-center font-bold text-center">
    <div className="select-none z-10 landing-poster-container relative">
    <button onClick={() => trackAndGoTo('https://t.me/acemakemoney', 'Purchase')} className="tg-btn"></button>
    <button onClick={() => trackAndGoTo('https://lin.ee/i7A17F8', 'Purchase')} className="line-btn"></button>
    </div>
    <MetaPixel />
  </div>)
}

const Landing1 = () => {
  const { t } = useTranslation();
  const lineCanvasRef = useRef(null);
  const telegramCanvasRef = useRef(null);


  useEffect(() => {
    const ratio = window.devicePixelRatio || 1;
    // console.log("ratio", ratio)
    const loadImage = (src, canvasRef) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.crossOrigin = "Anonymous"; // Ensure there are no cross-origin issues
      image.src = src;
      image.onload = () => {
        const rect = canvas.getBoundingClientRect();
        canvas.style.maxWidth = '100vw'; // CSS to ensure responsiveness
        // canvas.style.maxheight = '174vw'; // CSS to ensure responsiveness
        canvas.style.width = '60vh'; // CSS to ensure responsiveness
        canvas.style.height = '100%';
        // canvas.style.border = '10px solid red'
        canvas.width = rect.width * ratio; // Ensure canvas is same size as image
        canvas.height = rect.height * ratio;
        // canvas.width = image.naturalWidth; // Use naturalWidth and naturalHeight for actual size
        // canvas.height = image.naturalHeight;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    };

    loadImage(landingLine, lineCanvasRef);
    loadImage(landingTelegram, telegramCanvasRef);
  }, []);

  const handleCanvasClick = (event) => {
    const clickedCanvas = [lineCanvasRef.current, telegramCanvasRef.current].find((ref, index) => {
      const canvas = ref;
      if (canvas === null) return false;
      const ctx = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();
      const ratio = window.devicePixelRatio || 1;


      // get view port width
      const viewPortWidth = window.innerWidth

      console.log("canvas", index, "rect", rect, "ratio", ratio)

      const x = (event.clientX - rect.left) * ratio * viewPortWidth / rect.width;
      const y = (event.clientY - rect.top) * ratio;


      // const x = (event.clientX - rect.left) * ratio * canvas.width / rect.width;
      // const y = (event.clientY - rect.top) * ratio * canvas.height / rect.height;
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      // filter the 3 + 4n pixel values not equal to 1
      // console.log("viewPortWidth", viewPortWidth, "rect.width", rect.width, "rect.height", rect.height, "canvas.width", canvas.width, "canvas.height", canvas.height)
      console.log(`Clicked at (${x}, ${y}) - RGBA(${pixel.join(", ")})`);

      return pixel[3] !== 0; // Check if the pixel is not transparent
    });

    if (clickedCanvas) {
      const url = clickedCanvas === lineCanvasRef.current ? 'https://liff.line.me/1645278921-kWRPP32q/?accountId=acehackers' : 'https://t.me/acemakemoney';
      console.log("Navigating to:", url);
      window.location.href = url;
    } else {
      console.log("Click on transparent area");
    }
  };

  return (
    <div className="select-none relative landing-container h-screen flex justify-center items-center font-bold text-center" onClick={handleCanvasClick}>
      <canvas ref={lineCanvasRef} className="select-none z-20 absolute cursor-pointer max-h-[175vw] h-full w-full canvas-animate" />
      <canvas ref={telegramCanvasRef} className="select-none z-10 absolute cursor-pointer max-h-[175vw] h-full w-full canvas-animate-2" />
      <img draggable={false} className="select-none z-0 landing-poster" src={landingBg} />
      <MetaPixel />
    </div>
  );
};

export default Landing
