import { Suspense, lazy, useEffect, useState } from "react"
import Spinner from "@/components/Spinner"
import PageLoadingSpinner from "@/components/PageLoadingSpinner"

const OnboardingLazy = lazy(() => import("./Onboarding"))

const Register = (props: any) => {
  return (
    <Suspense fallback={<PageLoadingSpinner />}>
      <OnboardingLazy {...props} />
    </Suspense>
  )
}

const Login2 = (props) => {
  const [minimumLoadTimePassed, setMinimumLoadTimePassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMinimumLoadTimePassed(true);
    }, 100000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Suspense fallback={<PageLoadingSpinner />}>
      {minimumLoadTimePassed ? <LoginLazy {...props} /> : <PageLoadingSpinner />}
    </Suspense>
  );
};

const LoginLazy = lazy(() => import('./Onboarding').then(module => ({ default: module.Login })));
const Login = (props: any) => {
  return (
    <Suspense fallback={<PageLoadingSpinner />}>
      <LoginLazy {...props} />
    </Suspense>
  )
}

export default Register
export {
  Register,
  Login,
}