import { RootState, userSlice } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import ace_token from '@/assets/ace_token.png'
import { DownOutlined, SyncOutlined, MenuOutlined } from '@ant-design/icons';
import { useMutation } from "@tanstack/react-query";
import { getAceToken } from "@/util/fetches";
import { useEffect, useState } from "react";
import cx from 'classnames';


const AceToken = () => {

    const dispatch = useDispatch()

    const [rotate, setRotate] = useState(false)

    const mutation = useMutation({
        mutationFn: getAceToken,
        onSuccess(data, variables, context) {
            // console.log('get ace token result', data);
            dispatch(userSlice.actions.setToken(data));
            
            setTimeout(() => setRotate(false), 1000);
        },
    })

    const aceToken = useSelector<RootState>(
        (state) => state.user.aceToken
    );



    const refreshAceToken = () => {
        setRotate(true);
        mutation.mutate();
    }

    useEffect(() => {
        refreshAceToken();
    }, [])  

    const userSignIn = useSelector<RootState>(
        (state) => state.user.signInStatus
    );

    useEffect(() => {
        if (userSignIn) {
            console.log('refresh token after sign in', userSignIn);
            refreshAceToken();
        }
    }, [userSignIn])

    return (
        <div className="flex justify-between items-center ml-3 p-2 text-base rounded min-w-24 shadow-md" style={{ backgroundColor: 'var(--bg-button-dashboard)' }}>
            <img className='flex-start ml-1'
                width={20}
                height={20}
                src={ace_token}
                alt="" />
            <span className='flex-1 mx-1 font-bold' style={{ color: 'rgb(227, 148, 37)' }}>{(aceToken as number || 0).toFixed(2)}</span>
            <div className={cx('flex-end cursor-pointer', rotate && 'animate-spin')} onClick={refreshAceToken}>
                <SyncOutlined />
            </div>
        </div>
    )
}


export default AceToken;